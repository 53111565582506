<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="contractCanceledAdvancedHistory"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Nenhum registro correspondente encontrado"
        :sort-desc.sync="isSortDirDesc"
        @row-clicked="(item) => { item.checked = !item.checked }"
      >
        <template
          #cell(canceled_by)="{ item }"
          class="w-100"
        >
          <div>{{ item.canceled_by }}</div>
          <div class="row-subtitle">
            {{ formatISODateTimeDDMMYYHHMMSS(item.canceled_at) }}
          </div>
        </template>
        <template
          #cell(data.value_physician_duty)="{ item }"
          class="w-100"
        >
          <div>{{ formatCurrencyInt(item.data.value_physician_duty) }}</div>
          <div class="row-subtitle">
            {{ formatDateTimeDDMMYYHHMM(item.data.start_date) }} - {{ formatDateTimeDDMMYYHHMM(item.data.end_date) }}
          </div>
        </template>
        <template
          #cell(data.institution_name)="{ item }"
          class="w-100"
        >
          <div><strong>{{ item.data.institution_name }}</strong></div>
          <div class="row-subtitle">
            {{ item.data.workplace_name }}
          </div>
        </template>
        <template
          #cell(data.physician_name)="{ item }"
          class="w-100"
        >
          <div><strong>{{ item.data.physician_name }}</strong></div>
          <div class="row-subtitle">
            CPF: {{ maskHiddenCpf2(item.data.physician_cpf) }}
          </div>
        </template>
        <template
          #cell(data.account)="{ item }"
          class="w-100"
        >
          <div class="d-flex flex-column">
            <div class="account-info">
              <div>Banco</div>
              <div>{{ item.data.account_bank_code }} - {{ item.data.account_bank_name }}</div>
            </div>
            <div class="d-flex">
              <div class="account-info">
                <div>Agência</div>
                <div>{{ item.data.branch_number }}<span v-if="item.data.branch_code">-{{ item.data.branch_code }}</span></div>
              </div>
              <div class="account-info">
                <div>{{ resolveAccountType(item.data.account_type) }}</div>
                <div>{{ item.data.account_number }}-{{ item.data.account_code }}</div>
              </div>
            </div>
          </div>
        </template>

        <template
          #cell(amount_physician_duty)="{ item }"
          class="w-100"
        >
          {{ formatCurrency(item.amount_physician_duty) }}
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Exibindo {{ dataMeta.from }} a {{ dataMeta.to }} de {{ dataMeta.of }} entradas</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BPagination,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import {
  formatCurrency,
  formatCurrencyInt,
  formatDateTimeDDMMYYHHMM,
  formatISODateTimeDDMMYYHHMMSS,
  maskHiddenCpf2,
  resolveAccountType,
} from '@core/utils/utils'
import reportStoreModule from '@/views/report/reportStoreModule'
import useCanceledAdvancedHistoryList from './useCanceledAdvancedHistoryList'
import contractStoreModule from '../contractStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BPagination,
  },
  directives: {
    Ripple,
  },
  methods: {
    formatISODateTimeDDMMYYHHMMSS, formatCurrencyInt, maskHiddenCpf2, resolveAccountType,
  },
  setup() {
    const CONTRACT_APP_STORE_MODULE_NAME = 'app-contract'
    const REPORTS_STORE_MODULE_NAME = 'app-reports'

    // Register module
    if (!store.hasModule(CONTRACT_APP_STORE_MODULE_NAME)) store.registerModule(CONTRACT_APP_STORE_MODULE_NAME, contractStoreModule)
    if (!store.hasModule(REPORTS_STORE_MODULE_NAME)) store.registerModule(REPORTS_STORE_MODULE_NAME, reportStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CONTRACT_APP_STORE_MODULE_NAME)) store.unregisterModule(CONTRACT_APP_STORE_MODULE_NAME)
      if (store.hasModule(REPORTS_STORE_MODULE_NAME)) store.unregisterModule(REPORTS_STORE_MODULE_NAME)
    })

    const paymentDate = ref('')
    const loading = ref(false)
    const currentUserId = ref(null)
    const paymentRemittanceModal = ref(null)
    const paymentRemittance = ref(null)
    const contractCancelModal = ref(null)
    const contractToCancel = ref({
      contract_id: '',
      motive: '',
    })

    const {
      contractCanceledAdvancedHistory,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveDeadlineVariant,
      checkAll,
      showImediato,

      // Extra Filters
      roleFilter,
      planFilter,
      situationFilter,
      statusFilter,
      createPaymentRemittance,
      institutionId,
      institutionOptions,
    } = useCanceledAdvancedHistoryList()

    return {
      checkAll,
      contractToCancel,
      contractCancelModal,
      loading,
      contractCanceledAdvancedHistory,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      paymentDate,
      showImediato,
      // Filter
      avatarText,
      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveDeadlineVariant,
      currentUserId,
      // Extra Filters
      roleFilter,
      planFilter,
      situationFilter,
      statusFilter,
      formatCurrency,
      formatDateTimeDDMMYYHHMM,
      createPaymentRemittance,
      paymentRemittanceModal,
      paymentRemittance,
      institutionId,
      institutionOptions,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.dates-row {
  max-width: 500px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select';

tr:has(.custom-checkbox.checked) {
  background-color: rgba(98, 98, 98, 0.05);
}
.row-subtitle {
  font-size: 12px;
  color: #959a9c;
}
.account-info {
  display: flex;
  flex-direction: column;
  margin-right: 5px;
  div {
    font-size: 13px;
    line-height: 13px;
  }
  div:first-child {
    font-size: 10px;
    color: #959a9c;
  }
}
</style>
